.PlaylistSelector  {
    width: 100%;
    /* background-color: aliceblue; */
}

.PlaylistSelector .selector {
    width: 90%;
    display: inline-block;
    margin: 0;
    background: #222;
    color: #f8f8f8;
    height: auto !important;
    padding: 12px;
    text-align: center;
    opacity: 1;
    font-size: 18px;
    border: 1px solid #222;
}

.PlaylistSelector .menu {
    font-weight: bold;
    color: #ccc;
    display: inline-block;
    width: 10%;
    padding: 0;
    border: none;
    font-size: 32px;
}

.PlaylistSelector .menu:hover {
    color: #fff;
}